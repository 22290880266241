import { useState, useEffect } from 'react';
import { default as MaterialModal } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';

import getCountries from '../../utils/getCountries';
import Close from '../../images/close.svg';
import Button from '../Button';
import './modal.scss';

const style = {
  position: 'absolute',
  top: 180,
  left: 'calc(50% - 161px)',
  width: 323,
  height: 432,
  bgcolor: 'background.paper',
  outline: 0,
  borderRadius: 4,
  boxShadow: '0px 1px 18px 0px rgba(139, 147, 162, 0.25), 0px 6px 10px 0px rgba(139, 147, 162, 0.25), 0px 3px 5px -1px rgba(139, 147, 162, 0.25)',
};

const Modal = ({ isOpen, onClose, setValue, userCount, currentUser, lastUser }) => {
  const [nombre, setNombre] = useState(currentUser?.nombre || '');
  const [pais, setPais] = useState(currentUser?.pais || null);
  const [ano, setAno] = useState(currentUser?.ano || null);
  const [genero, setGenero] = useState(currentUser?.genero || null);
  const [listOfSpecialPaises, setListOfSpecialPaises] = useState([]);
  const [listPaises, setListPaises] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    setUpPaises();
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    setNombre(currentUser.nombre);
    setPais(currentUser.pais);
    setAno(currentUser.ano);
    setGenero(currentUser.genero);
  }, [currentUser]);

  const setUpPaises = async () => {
    const { listOfPaises, listOfSpecialPaises } = await getCountries();
    setListPaises(listOfPaises);
    setListOfSpecialPaises(listOfSpecialPaises);
  }

  const getTitle = () => {
    let title = '';
    if (currentUser) {
      switch(currentUser.idx) {
        case 0:
          title = 'Editar';
          break;
        case 1:
          title = 'Editar madre o padre';
          break;
        case 2:
          title = 'Editar abuela o abuelo';
          break;
        case 3:
          title = 'Editar bisabuela o bisabuelo';
          break;
        default:
          title = 'Editar bisabuela o bisabuelo';
      }
      return title;
    }
    switch(userCount) {
      case 0:
        title = 'Empezá por vos';
        break;
      case 1:
        title = 'Agregá madre o padre';
        break;
      case 2:
        title = 'Agregá abuela o abuelo';
        break;
      case 3:
        title = 'Agregá bisabuela o bisabuelo';
        break;
      default:
        title = 'Agregá bisabuela o bisabuelo';
    }
    return title;
  }

  const handlePaisChange = (event) => setPais(event.target.value);
  const handleGeneroChange = (event) => setGenero(event.target.value);

  const handleClose = () => {
    setPais(null);
    setGenero(null);
    onClose();
  }

  const anoIsValid = () => ano > 1600 && ano < 2030 && ano < (lastUser?.ano || 2030);

  const handleSubmit = () => {
    const value = {
      nombre,
      pais,
      ano,
      genero
    }
    setIsSubmitted(true);

    if (nombre !== '' && pais && anoIsValid() && genero) {
      const index = currentUser ? currentUser.idx : null;
      setValue(value, index);
      setIsSubmitted(false);
      setNombre('');
      setPais(null);
      setAno(null);
      setGenero(null);
    }
  }

  return (
    <MaterialModal
      open={isOpen}
      className='modal'
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <header>
          <h2>{getTitle()}</h2>
          <div className='close' onClick={handleClose}><img src={Close} alt='close' /></div>
        </header>
        <div className='fields-content'>
          <TextField 
            id='nombre' 
            label='Nombre' 
            variant='outlined' 
            className='field' 
            fullWidth 
            required
            defaultValue={currentUser?.nombre || ''}
            error={nombre === '' && isSubmitted}
            onChange={(e) => setNombre(e.target.value)}
          />
          <FormControl fullWidth className='field'>
            <InputLabel 
              id='pais-label' 
              required 
              error={!pais && isSubmitted}
            >
              Pa&iacute;s
            </InputLabel>
            <Select
              labelId='pais-label'
              id='pais'
              value={currentUser?.pais || pais}
              label='Pais'
              error={!pais && isSubmitted}
              onChange={handlePaisChange}
            >
              {listOfSpecialPaises.map(pais => (
                <MenuItem value={pais.name} key={pais.name}>{pais.name}</MenuItem>
              ))}
              <ListSubheader>Otros pa&iacute;ses</ListSubheader>
              {listPaises.map(pais => (
                <MenuItem value={pais.name} key={pais.name}>{pais.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField 
            id='nacimiento' 
            label='Año de nacimiento' 
            variant='outlined' 
            className='field' 
            fullWidth 
            required
            defaultValue={currentUser?.ano || null}
            error={!anoIsValid() && isSubmitted}
            onChange={(e) => setAno(e.target.value)}
          />
          <FormControl fullWidth className='field'>
            <InputLabel 
              id='genero-label' 
              required
              error={!genero && isSubmitted}
            >
              G&eacute;nero
            </InputLabel>
            <Select
              labelId='genero-label'
              id='genero'
              value={currentUser?.genero || genero}
              label='Genero'
              required
              error={!genero && isSubmitted}
              onChange={handleGeneroChange}
              >
              <MenuItem value='Hombre'>Hombre</MenuItem>
              <MenuItem value='Mujer'>Mujer</MenuItem>
            </Select>
          </FormControl>
        </div>
        <footer>
          <Button primary={false} click={handleClose}>Cancel</Button>
          <Button click={handleSubmit}>{`${currentUser ? 'Editar' : 'Agregar'}`}</Button>
        </footer>
      </Box>
    </MaterialModal>
  );
}

export default Modal;